import * as React from "react";

export function ListAddProp(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 128 128" {...props}>
			<circle cx={14.24} cy={22.67} r={14.24} />
			<circle cx={14.24} cy={63.5} r={14.24} />
			<circle cx={14.24} cy={104.42} r={14.24} />
			<path d="M124.2 15.65H46.34a3.81 3.81 0 00-3.8 3.8v6.65a3.81 3.81 0 003.8 3.8h5.33a37.4 37.4 0 0131.8 0h40.73a3.81 3.81 0 003.8-3.8v-6.65a3.81 3.81 0 00-3.8-3.8zM124.2 97.3H83a37.35 37.35 0 01-31 0h-5.66a3.81 3.81 0 00-3.8 3.8v6.65a3.81 3.81 0 003.8 3.8h77.86a3.81 3.81 0 003.8-3.8v-6.65a3.81 3.81 0 00-3.8-3.8zM124.2 57h-19.94a36.93 36.93 0 01-.26 14.22h20.2a3.81 3.81 0 003.8-3.8v-6.64a3.81 3.81 0 00-3.8-3.78z" />
			<path d="M67.56 28.25a35.25 35.25 0 100 70.49 35.25 35.25 0 100-70.49z" />
			<path
				d="M81.71 66.61h-11v11.2a3.32 3.32 0 01-3.12 3.31 3.22 3.22 0 01-3.12-3.31v-11.2h-11a3.11 3.11 0 01-3.12-3.11 3 3 0 012.91-3.11h11.18v-11.2a3.32 3.32 0 013.12-3.31 3.22 3.22 0 013.12 3.31v11.2h11a3.11 3.11 0 013.12 3.11 3 3 0 01-2.91 3.11h-.21z"
				fill="#e6e6e6"
			/>
		</svg>
	);
}

export function DeleteFieldIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 128 128" {...props}>
			<path d="M120.68 16.46H84.36v-6.27A11.18 11.18 0 0073.11-1.06H53.18a11.18 11.18 0 00-11.25 11.25v6.27H7.21a3.21 3.21 0 100 6.43h8l7.07 93.22A13 13 0 0035.18 128h56.09a12.83 12.83 0 0012.86-11.89l7.07-93.22h9.64a3.22 3.22 0 003.21-3.21 3.37 3.37 0 00-3.37-3.22zm-72.32-6.27a4.74 4.74 0 014.82-4.82h20.09a4.74 4.74 0 014.82 4.82v6.27H48.36zm49.18 105.27a6.47 6.47 0 01-6.43 5.95H35.18a6.47 6.47 0 01-6.43-5.95l-7.07-92.73h83.09z" />
			<path d="M63.14 39.44a3.22 3.22 0 00-3.21 3.21V102a3.21 3.21 0 106.43 0V42.66a3.22 3.22 0 00-3.22-3.22zM80.82 42.5l-3.21 59.3a3.22 3.22 0 003.05 3.38h.16a3.19 3.19 0 003.18-3.06l3.21-59.31a3.22 3.22 0 00-3.05-3.38 3.34 3.34 0 00-3.34 3.07zM42.09 39.44A3.34 3.34 0 0039 42.82l3.21 59.31a3.19 3.19 0 003.21 3.05h.16a3.34 3.34 0 003.05-3.38l-3.16-59.3a3.34 3.34 0 00-3.38-3.06z" />
		</svg>
	);
}
