import * as React from "react";

export const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 128 128" {...props}>
		<path d="M120.68 16.46H84.36v-6.27A11.18 11.18 0 0073.11-1.06H53.18a11.18 11.18 0 00-11.25 11.25v6.27H7.21a3.21 3.21 0 100 6.43h8l7.07 93.22A13 13 0 0035.18 128h56.09a12.83 12.83 0 0012.86-11.89l7.07-93.22h9.64a3.22 3.22 0 003.21-3.21 3.37 3.37 0 00-3.37-3.22zm-72.32-6.27a4.74 4.74 0 014.82-4.82h20.09a4.74 4.74 0 014.82 4.82v6.27H48.36zm49.18 105.27a6.47 6.47 0 01-6.43 5.95H35.18a6.47 6.47 0 01-6.43-5.95l-7.07-92.73h83.09z" />
		<path d="M63.14 39.44a3.22 3.22 0 00-3.21 3.21V102a3.21 3.21 0 106.43 0V42.66a3.22 3.22 0 00-3.22-3.22zM80.82 42.5l-3.21 59.3a3.22 3.22 0 003.05 3.38h.16a3.19 3.19 0 003.18-3.06l3.21-59.31a3.22 3.22 0 00-3.05-3.38 3.34 3.34 0 00-3.34 3.07zM42.09 39.44A3.34 3.34 0 0039 42.82l3.21 59.31a3.19 3.19 0 003.21 3.05h.16a3.34 3.34 0 003.05-3.38l-3.16-59.3a3.34 3.34 0 00-3.38-3.06z" />
	</svg>
);

// add by Harper from the Noun Project
// https://thenounproject.com/term/add/1623623

export const AddIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 128 128" {...props}>
		<path d="M64 12.95a51.05 51.05 0 000 102.1 51.05 51.05 0 000-102.1z" />
		<path d="M84.42 68.5H68.5v16.22a4.8 4.8 0 01-4.5 4.8 4.65 4.65 0 01-4.5-4.8V68.5H43.58a4.5 4.5 0 01-4.5-4.5 4.35 4.35 0 014.2-4.5H59.5V43.28a4.8 4.8 0 014.5-4.8 4.65 4.65 0 014.5 4.8V59.5h15.92a4.5 4.5 0 014.5 4.5 4.35 4.35 0 01-4.2 4.5z" />
	</svg>
);
