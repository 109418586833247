import * as React from "react";
import "./style.less";

export default function LoaderFace() {
	return (
		<div id="loader-face">
			<svg width="75" viewBox="0 0 171 280" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					id="border"
					d="M165 3H6C4.34315 3 3 4.34315 3 6V49V83.5V274.003C3 275.66 4.34314 277 6 277H165C166.657 277 168 275.657 168 274V6C168 4.34315 166.657 3 165 3Z"
				/>
				<g id="content">
					<path d="M55 14C55 14 64.2295 14 70.1434 14C88.0515 14 116 14 116 14V31H70.1434H55V14Z" />
					<path d="M14 15C14 14.4477 14.4477 14 15 14H53V31H15C14.4477 31 14 30.5523 14 30V15Z" />
					<path d="M118 14H156C156.552 14 157 14.4477 157 15V30C157 30.5523 156.552 31 156 31H118V14Z" />
					<rect x="14" y="45" width="143" height="54" />
					<path d="M14 114C14 113.448 14.4437 113 14.996 113C19.3959 113 37.4057 113 49.5 113C88.4907 113 147.785 113 156.035 113C156.587 113 157 113.448 157 114V129C157 129.552 156.552 130 156 130H49.5H15C14.4477 130 14 129.552 14 129V114Z" />
					<path d="M14 145C14 144.448 14.4437 144 14.996 144C19.3959 144 37.4057 144 49.5 144C88.4907 144 147.785 144 156.035 144C156.587 144 157 144.448 157 145V160C157 160.552 156.552 161 156 161H49.5H15C14.4477 161 14 160.552 14 160V145Z" />
					<rect x="54" y="203" width="63" height="63" />
				</g>
			</svg>
		</div>
	);
}
